import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	Code,
	ImportExample,
	Header,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { Paragraph } from "../paragraph/paragraph";
import { TextContainer } from "../text-container/text-container";
import { Ul, Ol } from "./list";

export const Meta: DocPageMeta = {
	category: "Text",
	title: "List",
	notepad: "https://hackmd.io/bSfzNB9aToS64O4esW1pog",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="List"
			subTitle="Use this component to vertically group related text-only items. Use an ordered list to communicate a specific priority, hierarchy, or sequence. Use an unordered list when the order of the items is not important."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={[Ul, Ol]} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The main difference between the two components is that the items in the ordered list
					component feature numbers, while the items in the unordered list feature bullets.
				</Paragraph>
				<Header.H3>Default unordered list</Header.H3>
				<Paragraph>
					Here you can see an example of the default appearance of an unordered list.
				</Paragraph>
				<Example fn={unorderedListExample} />
				<Header.H3>Default ordered list</Header.H3>
				<Paragraph>
					Here you can see an example of the default appearance of an ordered list.
				</Paragraph>
				<Example fn={orderedListExample} />
				<Header.H3>Nested lists</Header.H3>
				<Paragraph>
					List components can be nested inside each other, as you can see in the example below.
					Nested lists do not have to be of the same type as the parent list.
				</Paragraph>
				<Example fn={nestedListExample} />
				<Header.H2>Properties</Header.H2>
				<Header.H3>Unordered list</Header.H3>
				<Knobs
					component={Ul}
					initialProps={{
						items: ["Darth Vader", "Luke Skywalker", "Princess Leia"],
					}}
					exampleValues={{
						items: {
							"initial items": ["Darth Vader", "Luke Skywalker", "Princess Leia"],
							"short items": ["X", "Y", "Z"],
							"long items": [
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
							],
						},
					}}
				/>
				<Header.H3>Ordered list</Header.H3>
				<Knobs
					component={Ol}
					initialProps={{
						items: [
							"Star Wars: A New Hope (1977)",
							"Star Wars: The Empire Strikes Back (1980)",
							"Star Wars: Return of the Jedi (1983)",
						],
					}}
					exampleValues={{
						items: {
							"initial items": [
								"Star Wars: A New Hope (1977)",
								"Star Wars: The Empire Strikes Back (1980)",
								"Star Wars: Return of the Jedi (1983)",
							],
							"short items": ["X", "Y", "Z"],
							"long items": [
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
								"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
							],
						},
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							"Use a list when you need to group related items that only consist of text.",
							"Use for three or more items",
							"Use an ordered list when you need to communicate a specific priority, hierarchy, or sequence.",
							"Use an unordered list when the order of the items is not important.",
							"List items in unordered lists in a logical manner (e.g. alphabetically), to make it easy to find items.",
							"Do not nest lists more than two levels deep. Break them up into separate lists instead.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Paragraph>
						The items consist of complex data with multiple dimensions. Use a{" "}
						<a href="https://my2.siteimprove.com/Styleguide/components/base-table">table</a> or{" "}
						<a href="https://my2.siteimprove.com/Styleguide/components/list-table">list table</a>{" "}
						instead.
					</Paragraph>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						Keep list items as short as possible and limited to 1 or 2 pieces of information.
					</Paragraph>
					<Paragraph>
						The component’s implementation ensures that it automatically follows these guidelines:
					</Paragraph>
					<Ul
						items={[
							<>
								Use proper semantic markup, meaning: wrap <Code>li</Code> elements in either a{" "}
								<Code>ul</Code> or <Code>ol</Code> element.
							</>,
							<>
								Add <Code>role=“list”</Code> to <Code>ul</Code> and <Code>ol</Code> elements.
							</>,
							<>
								An <Code>aria-label</Code> is not required.
							</>,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Use an unordered list to present key ideas in place of a longer paragraph.",
							<>
								Keep list items short, snappy, and limited to a single sentence. If that’s not
								possible, consider using <GatsbyLink to="../Paragraph">paragraphs</GatsbyLink> with{" "}
								<GatsbyLink to="../Heading">headings</GatsbyLink> instead. As you can see, this rule
								does not apply to this styleguide.
							</>,
							"Make sure list items are self-contained, but thematically similar, ideas. So, for instance, don't string list items together by using “or” or “and” at the end of an item.",
							<>
								Don’t end list items with punctuation &mdash; no full stops, no semicolons. This is
								another rule that does not apply to this styleguide.
							</>,
							"Don’t mix and match sentence structures. So, for instance, start each list item with a verb or a noun, and stick to that formula all the way through.",
							"Use a lead-in sentence when you need to give context to the list items. A lead-in is a partial sentence that is completed by the list items (e.g. “Web Security allows you to:”).",
							"If you’re using a lead-in sentence, make sure every list item makes sense when placed next to it (e.g. “Web security allows you to: protect your users and your reputation”).",
							"Use uppercase for the first word of a list item, unless it’s a continuation of a lead-in sentence.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const unorderedListExample = () => (
	<Ul items={["Darth Vader", "Luke Skywalker", "Princess Leia"]} />
);

const orderedListExample = () => (
	<Ol
		items={[
			"Star Wars: Episode IV – A New Hope (1977)",
			"Star Wars: Episode V – The Empire Strikes Back (1980)",
			"Star Wars: Episode VI – Return of the Jedi (1983)",
		]}
	/>
);

const nestedListExample = () => (
	<Ol
		items={[
			<>
				Original trilogy
				<Ol
					items={[
						"Star Wars: Episode IV – A New Hope (1977)",
						"Star Wars: Episode V – The Empire Strikes Back (1980)",
						"Star Wars: Episode VI – Return of the Jedi (1983)",
					]}
				/>
			</>,
			<>
				Prequel trilogy
				<Ol
					items={[
						"Star Wars: Episode I – The Phantom Menace (1999)",
						"Star Wars: Episode II – Attack of the Clones (2002)",
						"Star Wars: Episode III – Revenge of the Sith (2005)",
					]}
				/>
			</>,
			<>
				Sequel trilogy
				<Ol
					items={[
						"Star Wars: Episode VII – The Force Awakens (2015)",
						"Star Wars: Episode VIII – The Last Jedi (2017)",
						"Star Wars: Episode IX – The Rise of Skywalker (2019)",
					]}
				/>
			</>,
		]}
	/>
);
